<template>
  <div class="admin-wizard">
    <AdminHeader />
    <AdminBreadcrumb />
    <main class="content">
      <b-container>
        <b-row>
          <b-col md="3">
            <AdminSideNav />
          </b-col>
          <b-col md="9">
            <b-row>
              <b-col md="12">
                <b-card>
                  <b-card-body class="p-0">
                    <b-card-title>
                      Manage the endorsements you have not submitted to the WGAW
                      for approval.
                    </b-card-title>
                    <div class="guide-tip">
                      <b-row align-v="center">
                        <b-col cols="3" lg="1" class="icon">
                          <font-awesome-icon :icon="['fas', 'users']" />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="8"
                          order="3"
                          order-lg="2"
                          class="text"
                        >
                          <div v-if="hasUnsubmittedItems">
                            When you are ready to send your endorsements to the
                            WGAW to be vetted for eligibility, click the
                            <span class="font-weight-bold">Submit All</span>
                            button. The WGAW Elections office will review the
                            endorsement data collected and will contact you
                            directly to advise which endorsements are eligible
                            for inclusion in your non-candidate statement in the
                            election book. You may send your endorsements to the
                            Guild for review as often as you like up until the
                            {{
                              settings.lastDateOfEndorsementSubmission
                                | formatDate
                            }}
                            ({{
                              settings.lastDateOfEndorsementSubmission
                                | formatTime
                            }}
                            PDT) deadline. Endorsements which are not eligible
                            for inclusion in your non-candidate statement in the
                            election book may still appear on your candidate
                            website.
                          </div>
                          <div v-else>
                            Once you receive a new member endorsement on your
                            site it will be displayed here.
                          </div>
                        </b-col>
                        <b-col
                          cols="9"
                          lg="3"
                          order="2"
                          order-lg="3"
                          class="button"
                        >
                          <b-button
                            variant="outline-secondary"
                            size="sm"
                            v-b-modal.modal-theme-guide
                          >
                            View Example
                            <font-awesome-icon
                              :icon="['far', 'window-maximize']"
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                    <div v-if="hasUnsubmittedItems">
                      <b-button
                        variant="primary"
                        class="mt-3 mb-4 float-right"
                        @click="submitAll"
                        :disabled="saving || submitted"
                        ><b-spinner
                          small
                          style="position: relative; top: -3px"
                          v-if="saving"
                        ></b-spinner>
                        {{ submitText }}
                        <font-awesome-icon :icon="['fas', 'share']" />
                      </b-button>

                      <b-button
                        variant="outline-secondary"
                        class="mt-3 mb-4"
                        @click="onExportAll"
                        :disabled="exporting"
                        ><b-spinner
                          small
                          style="position: relative; top: -3px"
                          v-if="exporting"
                        ></b-spinner>
                        Export All
                        <font-awesome-icon :icon="['fas', 'file-excel']" />
                      </b-button>
                      <b-alert :show="!!errorMessage" variant="danger">{{
                        errorMessage
                      }}</b-alert>

                      <div
                        v-for="(statement, index) in endorsements"
                        :key="index"
                        class="mt-2"
                      >
                        <h5>Statement #{{ statement.number }}</h5>
                        <b-table
                          striped
                          responsive
                          ref="table"
                          :fields="fields"
                          :items="statement.tableItems"
                          class="mb-4"
                        >
                        </b-table>
                      </div>
                    </div>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>

            <AdminFooterButton
              @on-save="onSave"
              @on-back="onBack"
              @on-skip="onSkip"
              :hide-preview-button="true"
              :hide-skip-button="showDashboardButton"
              save-text-button="Continue"
              :invalid="false"
              :hide-save-button="showDashboardButton"
              :hide-dashboard-button="!showDashboardButton"
            />
          </b-col>
        </b-row>
      </b-container>
    </main>
    <ModalThemeGuide
      theme-id="endorsementList"
      :template-id="candidateModel.profileTheme"
    />
    <b-modal
      id="modal-deadline"
      title="Submission Deadline"
      @ok="handleOkDeadline"
      ok-only
    >
      <p>
        The submission deadline for endorsements to be vetted for eligibility
        for inclusion in your Non-Candidate Statement for the election book has
        passed, and can no longer be submitted to the WGAW. However, you will
        still will be able to post endorsements to your website until
        <strong
          >{{ settings.lastDateOfEndorsementSubmission | formatDate }}
          {{ settings.lastDateOfEndorsementSubmission | formatTime }}
          PDT</strong
        >. Click the <strong>OK</strong> button to continue to post any
        subsequent endorsements you have received directly on your Candidate
        Webpage.
      </p>
    </b-modal>
  </div>
</template>

<script>
import CandidateMixin from "@/mixins/candidate.mixin";
import SettingsMixin from "@/mixins/settings.mixin";
import Repository from "@/repositories/RepositoryFactory";

const ExportRepository = Repository.get("ExportRepository");

export default {
  name: "UnapprovedEndorsements",
  mixins: [CandidateMixin, SettingsMixin],
  data() {
    return {
      endorsementFile: null,
      loading: false,
      errorMessage: null,
      fields: [
        {
          label: "First Name",
          key: "firstName",
        },
        {
          label: "Last Name",
          key: "lastName",
        },
        {
          label: "Email",
          key: "email",
        },
        {
          label: "Status",
          key: "endorsementStatus",
        },
      ],
      unapproved: {
        items: [],
      },
      submitText: "Submit All",
      submitted: false,
      saving: false,
      exporting: false,
    };
  },
  computed: {
    showDashboardButton() {
      if (this.hasSubmittedItems) return false;
      else if (this.candidateModel.isPetition) return false;

      return true;
    },
    endorsements() {
      return this.candidateModel.endorsementStatementsTableItems.map(function (
        statement
      ) {
        return {
          ...statement,
          tableItems: statement.items.filter(
            (item) => item.endorsementStatus !== "Submitted"
          ),
        };
      });
    },
    settings() {
      return this.$store.getters["settings/current"];
    },
  },
  methods: {
    async handleOkDeadline(bvModalEvt) {
      // this.saving = true;
      // this.submitText = "Processing";
      // setTimeout(async () => {
      //   await this.$store.dispatch(
      //     "candidates/submitEndorsementsToAdmin",
      //     this.$route.params.id
      //   );
      //   this.submitText = "Submitted";
      //   this.submitted = true;
      //   this.saving = false;
      // }, 1000);
    },
    async submitAll() {
      try {
        if (
          new Date() < new Date(this.settings.lastDateOfEndorsementSubmission)
        ) {
          this.saving = true;
          this.submitText = "Processing";
          await this.$store.dispatch(
            "candidates/submitEndorsementsToAdmin",
            this.$route.params.id
          );
          this.submitText = "Submitted";
          this.submitted = true;
          this.saving = false;

          this.$router.push("approved-endorsements");
        } else {
          this.$bvModal.show("modal-deadline");
        }
      } catch (e) {
        console.log(e);
      }
    },
    async onExportAll() {
      try {
        this.exporting = true;
        setTimeout(async () => {
          await ExportRepository.ExportUnapprovedEndorsements(
            this.$route.params.id
          );
          this.exporting = false;
        }, 500);
      } catch (e) {
        console.log(e);
      }
    },
    onPreview() {},
    onSkip() {
      if (this.hasSubmittedItems) this.$router.push("approved-endorsements");
      else if (this.candidateModel.isPetition)
        this.$router.push("manage-petition");
    },
    onBack() {
      this.$router.push("add-endorsement");
    },
    onSave() {
      if (this.hasSubmittedItems) this.$router.push("approved-endorsements");
      else if (this.candidateModel.isPetition)
        this.$router.push("manage-petition");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base.scss";
</style>
